<template>
  <div class="table-filter-wrap">
    <template
        v-if="$store.getters.getConsolidationMeest.length > 0 && $store.getters.getConsolidationMeestLoading !== true">
      <div class="site-table-wrap products-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'consolidationMeest_Id',
            'consolidationMeest_date',
            'consolidationMeest_deliverySystem',
            'consolidationMeest_trackingNumber',
            'consolidationMeest_comment',
            'common_manage',
            ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th v-if="navTabs.meest.active"></th>
            <th>{{$t('consolidationMeest_Id.localization_value.value')}}</th>
            <th>{{$t('consolidationMeest_date.localization_value.value')}}</th>
<!--            <th>{{$t('consolidationMeest_deliverySystem.localization_value.value')}}</th>-->
            <th>{{$t('consolidationMeest_trackingNumber.localization_value.value')}}</th>
            <th>{{$t('consolidationMeest_comment.localization_value.value')}}</th>
            <th v-if="navTabs.meestSent.active">{{$t('consolidationMeest_sentDate.localization_value.value')}}</th>
            <th>{{$t('common_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>
  
          <tr v-for="(item, index) in $store.getters.getConsolidationMeest" :key="index">
            <td v-if="navTabs.meest.active">
              <DefaultCheckbox
                  class="empty-label mt-1"
                  :selectedNow="selectedNow"
                  :dataValue="item.id"
              />
            </td>
            <td>
              <div class="d-flex align-items-center">
                <router-link
                    v-if="navTabs.meest.active"
                    class="table-link btn-style"
                    :to="$store.getters.GET_PATHS.reportsConsolidationMeest + '/show/' + item.id">
                  #{{item.id}}
                </router-link>
                <router-link
                    v-else
                    class="table-link btn-style"
                    :to="$store.getters.GET_PATHS.reportsConsolidationMeestSent + '/show/' + item.id">
                  #{{item.id}}
                </router-link>
              </div>
            </td>
            <td>{{ item.created_at | moment("DD MMM, YYYY") }}</td>
<!--            <td>-->
<!--              Meest-->
<!--            </td>-->
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'tracking_number'"
              />
            </td>
            <td>
              <ValueHelper
                  v-if="!item.comment"
                  :value="item"
                  :deep="'comment'"
              />
              <TooltipTextHelper
                  v-else
                  :fullTextWidth="300">
                <template slot="text">
                  {{item.comment}}
                </template>
                <template slot="paragraph">
                  {{item.comment}}
                </template>
              </TooltipTextHelper>
            </td>
            <td v-if="navTabs.meestSent.active">
              <ValueHelper
                  :value="item.sent_at"
                  :date="true"
                  :dateType="'DD MMM, YYYY'"
              />
            </td>

            <td width="10%">
              <div class="table-right table-manage-list table-manage-list--small"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                'common_react',
                'common_edit',
                ])"></div>
                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                  <template slot="item">
                    <LinkButton
                        :value="$t(`common_edit.localization_value.value`)"
                        :type="'edit'"
                        :link="$store.getters.GET_PATHS.reportsConsolidationMeest + '/' + item.id"
                    />
                  </template>
                </ManagerButton>
              </div>
            </td>
          </tr>
  
  
  
          </tbody>
        </table>
  
        <div class="table-card" v-else>
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getConsolidationMeest"
                 :key="index"
            >
              <ConsolidationMeestTableMobile
                  :item="item"
                  :index="index"
                  :selectedNow="selectedNow"
                  :navTabs="navTabs"
              />
            </div>
          </div>
        </div>
  
        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                v-if="$store.getters.getConsolidationMeestCommonList.next_page_url !== null && $store.getters.getConsolidationMeest.length > 0"
                @click.native="$emit('showMore')"
                :count="$store.getters.getConsolidationMeestCommonList.total - $store.getters.getConsolidationMeestForPage * $store.getters.getConsolidationMeestCommonList.current_page < $store.getters.getConsolidationMeestForPage ?
                    $store.getters.getConsolidationMeestCommonList.total - $store.getters.getConsolidationMeestForPage * $store.getters.getConsolidationMeestCommonList.current_page:
                    $store.getters.getConsolidationMeestForPage"
            />
          </div>
        </div>
      </div>
    </template>

    <template
        v-if="$store.getters.getConsolidationMeestLoading === false && $store.getters.getConsolidationMeest.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>
  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import ConsolidationMeestTableMobile from "./ConsolidationMeestTableMobile/ConsolidationMeestTableMobile";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";

  export default {
    name: "ConsolidationMeestTable",

    components: {
      TooltipTextHelper,
      NoResult,
      ValueHelper,
      ConsolidationMeestTableMobile,
      ShowMore,
      DefaultCheckbox,
      LinkButton,
      ManagerButton,
    },

    mixins: [mixinDetictingMobile],

    props: {
      countFilterParams: Number,
      selectedNow: Boolean,
      navTabs: Object,
    },

    data() {
      return {

      }
    },

    methods: {


    }

  }
</script>

<style lang="scss" scoped>

  .products-table .table-manage-list .manager-btn{
    width: 165px;
  }

  .products-table .site-table tbody td:last-child {
    padding-right: 15px;
  }

  .products-table .ellipsis{
    max-width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inherit;
  }

  .clipping-text{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  td:first-child{
    max-width: 33px;
  }

  td:last-child{
    max-width: 120px;
  }

  .comment-item{
    margin: 0 -15px;
    padding: 10px 15px;
    display: block;

    &:nth-child(even){
      background: #F8F4EE;
    }

    &--right-align {
      text-align: right;
    }

    p{
      margin-bottom: 0;
      &:first-child{
        margin-bottom: 5px;
      }
    }
  }

</style>
