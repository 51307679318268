<template>
  <modal
      @close="$emit('close')"
      class="transaction-info-modal transaction-info"
  >
    <template slot="header">
      <span v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['cuspayments_transactions'])"></span>
        {{$t('cuspayments_transactions.localization_value.value')}}:
        {{$store.getters.getCusPaymentsItem.transaction_id}}
      </span>

    </template>
    <template slot="body">
      <div class="transaction-info__content"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}"
           v-if="payment = $store.getters.getCusPaymentsItem"
      >
        <span class="admin-edit" @click="editTranslate(translations)"></span>
        <div class="transaction-info__name">
          <template v-if="
                payment.payment_transaction_detail !== null &&
                payment.payment_transaction_detail.payment_transaction_contact !== null">
            {{payment.payment_transaction_detail.payment_transaction_contact.full_name}}
          </template>

          {{ payment.created_at | moment("DD MMM, YYYY") }} {{$t('common_at.localization_value.value')}}
          {{ payment.created_at | moment("HH:mm") }}
        </div>
        <div class="transaction-info__status"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate([
            'common_Paid',
            'status_Returned',
            'common_InProgress',
            'common_Rejected',
            'common_statusNew',
          ])"></span>
          <div class="d-flex"
               v-if="payment.status === CUSTOMERS_PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS">
            <StatusIcoBtn :type="'active'" class="mr-2 t-0"/>
            {{$t('common_Paid.localization_value.value')}}
          </div>
          <div class="d-flex"
               v-if="payment.status === CUSTOMERS_PAYMENTS_STATUSES.REFUND_TRANSACTION_STATUS">
            <StatusIcoBtn :type="'active'" class="mr-2 t-0"/>
            {{$t('status_Returned.localization_value.value')}}
          </div>
          <div class="d-flex"
               v-if="payment.status === CUSTOMERS_PAYMENTS_STATUSES.IN_PROGRESS_TRANSACTION_STATUS">
            <StatusIcoBtn :type="'dots'" class="mr-2 t-0"/>
            {{$t('common_InProgress.localization_value.value')}}
          </div>
          <div class="d-flex"
               v-if="payment.status === CUSTOMERS_PAYMENTS_STATUSES.CANCELED_TRANSACTION_STATUS ||
                          payment.status === CUSTOMERS_PAYMENTS_STATUSES.ERROR_STATUS">
            <StatusIcoBtn :type="'rejected'" class="mr-2 t-0"/>
            {{$t('common_Rejected.localization_value.value')}}
          </div>
          <div class="d-flex"
               v-if="payment.status === CUSTOMERS_PAYMENTS_STATUSES.ON_HOLD_STATUS">
            <StatusIcoBtn :type="'star'" class="mr-2 t-0"/>
            {{$t('common_statusNew.localization_value.value')}}
          </div>
        </div>

        <div class="transaction-info__section">
          <div class="transaction-info__section-title">
            {{$t('cuspayments_orderDetails.localization_value.value')}}
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('cuspayments_lotInvoiceNumber.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">

                <template v-if="shopLink.length > 0">
                  <a target="_blank" :href="shopLink + payment.payment_transaction_detail.l_number0"
                     class="site-link site-link--light brown clear-after text-decoration-underline">
                    <ValueHelper
                        v-if="payment.payment_transaction_detail.l_number0 !== null"
                        :value="payment.payment_transaction_detail.l_number0"
                    />
                    <ValueHelper
                        v-else
                        :value="payment.payment_transaction_detail.invoice_number"
                    />
                  </a>
                </template>
                <template v-else-if="payment.invoice">
                  <router-link
                      class="site-link site-link--light brown clear-after text-decoration-underline"
                      :to="`${$store.getters.GET_PATHS.financeUkrTreasuresLink}?id=${payment.invoice.invoice_instance.instance.id}`">
                    #<ValueHelper
                      :deep="'invoice.invoice_instance.instance.id'"
                      :value="payment"
                  />
                  </router-link>
                </template>
                <template v-else>
                  <ValueHelper
                      v-if="payment.payment_transaction_detail.l_number0 !== null"
                      :value="payment.payment_transaction_detail.l_number0"
                  />
                  <ValueHelper
                      v-else
                      :value="payment.payment_transaction_detail.invoice_number"
                  />
                </template>
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('cuspayments_trackingNumber.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <template v-if="transactionUrl && payment.tracking_number_url">
                  <a class="site-link btn-style" :href="payment.tracking_number_url">
                    <ValueHelper
                        :value="payment.tracking_number"
                    />
                  </a>
                </template>
                <ValueHelper
                    v-else
                    :value="payment.tracking_number"
                />
              </div>
            </div>
            <!--</div>-->

            <!--<div class="transaction-info__row">-->
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                Etsy, Ebay - {{$t('cuspayments_order.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <!--#39523:Svitlana Ukrainova (FedEx)-->
                —
              </div>
            </div>

            <div class="transaction-info__col" v-if="payment.shop">
              <div class="transaction-info__label">
                {{$t('shops_linkToShop.localization_value.value')}}
              </div>
              <div class="transaction-info__txt" style="word-break: break-all;">
                <a  target="_blank" rel="nofollow"
                    class="site-link"
                    v-if="payment.shop.shop_param && payment.shop.shop_param.shop_link"
                    :href="getShopLinkHref(payment.shop.shop_param.shop_link)"
                >
                  {{payment.shop.shop_param.shop_link}}
                </a>
              </div>
            </div>
          </div>

        </div>

        <div class="transaction-info__section">
          <div class="transaction-info__section-title">
            <div class="transaction-info__status-ico mb-1">
              <StatusIcoBtn
                  :type="'delivered'"
              />
            </div>
            {{$t('cuspayments_customerInformation.localization_value.value')}}
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('cuspayments_name.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    :value="payment.payment_transaction_detail.payment_transaction_contact.full_name"
                />
              </div>
            </div>

            <div class="transaction-info__col" v-if="payment.payment_transaction_detail !== null">
              <div class="transaction-info__label">
                {{$t('common_phone.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <template v-if="payment.payment_transaction_detail.payment_transaction_contact.phone">
                  {{payment.payment_transaction_detail.payment_transaction_contact.phone}}
                </template>
                <template v-else>
                  —
                </template>
              </div>
            </div>
            <div class="transaction-info__col" v-if="
            payment.payment_transaction_detail.payment_transaction_contact !== null">
              <div class="transaction-info__label">
                {{$t('common_email.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    :value="payment.payment_transaction_detail.payment_transaction_contact.email"
                />
              </div>
            </div>

            <div class="transaction-info__col" v-if="payment.payment_transaction_detail !== null">
              <div class="transaction-info__label">
                {{$t('common_address.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    :value="payment.payment_transaction_detail.payment_transaction_contact.address"
                />
              </div>
            </div>

            <div class="transaction-info__col" v-if="payment.payment_transaction_detail !== null">
              <div class="transaction-info__label">
                {{$t('common_state.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    :value="payment.payment_transaction_detail.payment_transaction_contact.state"
                />
              </div>
            </div>

            <div class="transaction-info__col" v-if="payment.payment_transaction_detail !== null">
              <div class="transaction-info__label">
                {{$t('common_city.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    :value="payment.payment_transaction_detail.payment_transaction_contact.city"
                />
              </div>
            </div>

            <div class="transaction-info__col" v-if="payment.payment_transaction_detail !== null">
              <div class="transaction-info__label">
                {{$t('common_country.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    :value="payment.payment_transaction_detail.payment_transaction_contact.country_code"
                />
              </div>
            </div>
            <div class="transaction-info__col" v-if="payment.payment_transaction_detail !== null">
              <div class="transaction-info__label">
                {{$t('cuspayments_zipCode.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    :value="payment.payment_transaction_detail.payment_transaction_contact.zip_code"
                />
              </div>
            </div>
            <!--<div class="transaction-info__col" v-if="payment.payment_transaction_detail !== null">-->
            <!--<div class="transaction-info__label">-->
            <!--{{$t('cuspayments_addressStatus.localization_value.value')}}-->
            <!--</div>-->
            <!--<div class="transaction-info__txt">-->
            <!--<ValueHelper-->
            <!--:value="payment.payment_transaction_detail.address_status"-->
            <!--/>-->
            <!--</div>-->
            <!--</div>-->
          </div>
        </div>


        <div class="transaction-info__section">
          <div class="transaction-info__section-title">
            <template v-if="transactionType === PAYMENT_TYPE_NAME.payPall">
              {{$t('cuspayments_paypalTransactions.localization_value.value')}}
            </template>
            <template v-else>
              {{$t('cuspayments_transactions.localization_value.value')}}
            </template>
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col"
                 :class="{ 'w-100' : transactionType === PAYMENT_TYPE_NAME.payPall || transactionType === PAYMENT_TYPE_NAME.authroize}"
            >
              <div class="transaction-info__label">
                {{$t('importO_transactionNumber.localization_value.value')}}
              </div>
              <div class="transaction-info__txt d-flex align-items-center">
                <div class="transaction-info__ico mr-2">
                  <img v-if="transactionType === PAYMENT_TYPE_NAME.payPall" src="/img/company-icons-group/transaction-paypal-icon.png" alt="ico">
                  <img v-if="transactionType === PAYMENT_TYPE_NAME.authroize" src="/img/company-icons-group/transaction-authorize-icon.png" alt="ico">
                </div>
                {{payment.transaction_id}}
              </div>
            </div>
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('cuspayments_totalAmount.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                ${{payment.payment_total}}
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('cuspayments_SKLADUSAFee.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                ${{payment.warehouse_commission_amount}}
                <template v-if="isAdmin">
                  <span class="site-link site-link--light brown text-decoration-underline clear-after"
                          v-if="!payment.warehouse_commission_amount || payment.warehouse_commission_amount === AMOUNT_DEFAULT_VALUE"
                          @click="addWarehouseCommission(payment.id)"
                    >
                    {{$t('cuspayments_SKLADUSAFeeAdd.localization_value.value')}}
                  </span>
                    <span class="site-link site-link--light brown text-decoration-underline clear-after"
                          v-else
                          @click="removeWarehouseCommission(payment.id)"
                    >
                    {{$t('cuspayments_SKLADUSAFeeRemove.localization_value.value')}}
                  </span>
                </template>
              </div>
            </div>

            <div class="transaction-info__col">
              <div class="transaction-info__label">
                <template v-if="transactionType === PAYMENT_TYPE_NAME.payPall">
                  {{$t('cuspayments_paypalFee.localization_value.value')}}
                </template>
                <template v-if="transactionType === PAYMENT_TYPE_NAME.authroize">
                  {{$t('cuspayments_authorizeFee.localization_value.value')}}
                </template>
              </div>
              <div class="transaction-info__txt">
                ${{payment.payment_commission}}
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('cuspayments_total.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <span v-if="$store.getters.getCusPaymentsItem.status == CUSTOMERS_PAYMENTS_STATUSES.REFUND_TRANSACTION_STATUS">-</span>
                ${{payment.user_amount}}
              </div>
            </div>

            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('cuspayments_tax.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                $
                <ValueHelper
                    :value="payment.payment_tax"
                />
              </div>
            </div>
            <div class="transaction-info__col" v-if="transactionType === PAYMENT_TYPE_NAME.authroize">
            </div>
            <div class="transaction-info__col" v-if="transactionType === PAYMENT_TYPE_NAME.authroize">
              <div class="transaction-info__label">
                {{$t('mypayments_perTransactionFee.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                $<ValueHelper
                  :value="$store.getters.getUserSettings.payments.authorize.authorize_per_transaction.value"
              />
              </div>
            </div>
            <div class="transaction-info__col" v-if="transactionType === PAYMENT_TYPE_NAME.authroize">
            </div>

            <div class="transaction-info__col" v-if="transactionType === PAYMENT_TYPE_NAME.payPall">
              <!--<div class="transaction-info__label">-->
              <!--{{$t('cuspayments_systemComission.localization_value.value')}}-->
              <!--</div>-->
              <!--<div class="transaction-info__txt">-->
              <!--${{$store.getters.getCusPaymentsItem.warehouse_amount}}-->
              <!--</div>-->
            </div>

            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('cuspayments_netAmount.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                ${{payment.warehouse_amount}}
              </div>
            </div>
          </div>

          <div class="transaction-info__row" v-if="transactionType === PAYMENT_TYPE_NAME.authroize">
            <div class="transaction-info__col" v-if="payment.payment_system === PAYMENT_TYPE_NAME.authroize">
              <div class="transaction-info__label">
                {{$t('mypayments_aVSResponse.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <template v-if="payment.payment_transaction_detail">
                  <ValueHelper
                      :value="payment.payment_transaction_detail.avs"
                  />
                </template>
              </div>
            </div>

            <div class="transaction-info__col" v-if="transactionType === PAYMENT_TYPE_NAME.authroize">
              <div class="transaction-info__label">
                {{$t('mypayments_customerIp.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <template v-if="payment.payment_transaction_detail">
                  <ValueHelper
                      :value="payment.payment_transaction_detail.payer_ip"
                  />
                </template>
              </div>
            </div>

            <div class="transaction-info__col" v-if="transactionType === PAYMENT_TYPE_NAME.authroize">
              <div class="transaction-info__label">
                {{$t('mypayments_fraudFilters.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    :value="payment.payment_transaction_detail.fraud_filter"
                />
              </div>
            </div>

            <div class="transaction-info__col" v-if="
            payment.payment_system === PAYMENT_TYPE_NAME.authroize && (
            payment.status === CUSTOMERS_PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS ||
            payment.status === CUSTOMERS_PAYMENTS_STATUSES.ON_HOLD_STATUS)">
              <div class="transaction-info__label">
                {{$t('mypayments_cardCodeResponse.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                {{$t('mypayments_cVVMatched.localization_value.value')}}
              </div>
            </div>
          </div>

          <div class="transaction-info__row" v-if="transactionType === PAYMENT_TYPE_NAME.payPall">
            <div class="transaction-info__col w-100">
              <div class="transaction-info__label">
                {{$t('cuspayments_items.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    :value="payment.payment_transaction_detail.items"
                />
              </div>
            </div>
          </div>
        </div>



      </div>
    </template>
<!--    <template slot="footer">-->
<!--      <div class="transaction-info__btn" v-if="_.has(currentPermissions, PERMISSIONS.PAYPAL_CASE_GENERAL)">-->
<!--        <router-link :to="$store.getters.GET_PATHS.financePayPalCasesCreate + '?transaction=' + $store.getters.getCusPaymentsItem.transaction_id">-->
<!--          <span class="site-link site-link&#45;&#45;alt brown mr-3 mt-3 mb-3">-->
<!--            {{$t('cuspayments_openComplain.localization_value.value')}}-->
<!--          </span>-->
<!--        </router-link>-->

<!--        <template v-if="_.has(currentPermissions, PERMISSIONS.PAYPAL_CASE_GENERAL)">-->
<!--          <div class="transaction-info__btn__btn"-->
<!--               v-if="$store.getters.getCusPaymentsItem.status === CUSTOMERS_PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS &&-->
<!--           $store.getters.getCusPaymentsItem.canMakeRefund &&-->
<!--           $store.getters.getCusPaymentsItem.freeAmount &&-->
<!--            $store.getters.getCusPaymentsItem.canMakeComplaint">-->
<!--            <router-link :to="$store.getters.GET_PATHS.financeRequestReturnCreate + '/' + $store.getters.getCusPaymentsItem.id">-->
<!--              <MainButton-->
<!--                  :value="$t('cuspayments_requestRefund.localization_value.value')"-->
<!--                  class="wfc"-->
<!--              />-->
<!--            </router-link>-->
<!--          </div>-->
<!--        </template>-->

<!--      </div>-->
<!--    </template>-->
  </modal>
</template>

<script>
import Modal from "../../../commonModals/Modal";
// import MainButton from "../../../UI/buttons/MainButton/MainButton";
// import CheckIco from '../../../../../../public/img/common/check.svg?inline'
import StatusIcoBtn from "../../../UI/status/StatusIcoBtn/StatusIcoBtn";
import {AMOUNT_DEFAULT_VALUE, CUSTOMERS_PAYMENTS_STATUSES, DEFAULT_SHOP_TYPES} from "../../../../staticData/staticVariables";
import {PAYMENT_TYPE_NAME} from "../../../../staticData/staticVariables";
import ValueHelper from "../../../coreComponents/ValueHelper/ValueHelper";



export default {
  name: "TransactionInfoPopup",
  components: {
    Modal,
    // MainButton,
    // CheckIco,
    StatusIcoBtn,
    ValueHelper,
  },

  props: {
    itemId: Boolean,
    transactionType: String,
    transactionUrl: {
      type: Boolean,
      default: false,
    }
  },

  watch: {
    itemId(newVal) {
      this.itemId = newVal
    },
    transactionType(newVal) {
      this.transactionType = newVal
    },
  },

  data() {
    return {
      CUSTOMERS_PAYMENTS_STATUSES: CUSTOMERS_PAYMENTS_STATUSES,
      DEFAULT_SHOP_TYPES: DEFAULT_SHOP_TYPES,
      AMOUNT_DEFAULT_VALUE: AMOUNT_DEFAULT_VALUE,
      PAYMENT_TYPE_NAME: PAYMENT_TYPE_NAME,
      translations: [
        'cuspayments_completed',
        'cuspayments_orderDetails',
        'cuspayments_lotInvoiceNumber',
        'cuspayments_trackingNumber',
        'cuspayments_name',
        'cuspayments_order',
        'cuspayments_customerInformation',
        'common_phone',
        'common_email',
        'common_address',
        'common_city',
        'common_country',
        'cuspayments_addressStatus',
        'cuspayments_zipCode',
        'cuspayments_paypalTransactions',
        'cuspayments_transactions',
        'cuspayments_paypalTransactions',
        'cuspayments_totalAmount',
        'cuspayments_total',
        'cuspayments_paypalFee',
        'cuspayments_SKLADUSAFee',
        'cuspayments_openComplain',
        'cuspayments_requestRefund',
      ],

      shopType: this.$store.getters.getCusPaymentsItem.shop ? this.$store.getters.getCusPaymentsItem.shop.shop_type.name : '',
      shopLink: '',
    }
  },

  mounted() {
    // this.getTransaction()

    this.shopType = this.$store.getters.getCusPaymentsItem.shop ? this.$store.getters.getCusPaymentsItem.shop.shop_type.name : ''
    if(this.shopType === DEFAULT_SHOP_TYPES.etsy.type){
      this.shopLink = 'https://www.etsy.com/listing/'
    }
    if(this.shopType === DEFAULT_SHOP_TYPES.ebay.type){
      this.shopLink = 'https://www.ebay.com/itm/'
    }

  },

  methods: {

    addWarehouseCommission(id) {
      let data = {
        paymentTransactionId: id,
        processType: 1,
      }
      this.$store.dispatch('changeWarehouseCommission', data).then(response => {
        if(!response.data.data.status){
          this.openNotify('error', 'common_notificationUndefinedError')
        }
        else {
          this.$store.commit('setCusPaymentsItem', {cusPaymentsItem: response.data.data.paymentTransaction})
          this.openNotify('success', 'common_notificationStatusChanged')
        }
      })
    },

    removeWarehouseCommission(id) {
      let data = {
        paymentTransactionId: id,
        processType: 0,
      }
      this.$store.dispatch('changeWarehouseCommission', data).then(response => {
        if(!response.data.data.status){
          this.openNotify('error', 'common_notificationUndefinedError')
        }
        else {
          this.$store.commit('setCusPaymentsItem', {cusPaymentsItem: response.data.data.paymentTransaction})
          this.openNotify('success', 'common_notificationStatusChanged')
        }
      })
    },

    getShopLinkHref(url) {
      if (!url) return ''

      if (url.trim().indexOf('http') === 0 || url.trim().indexOf('//') === 0) {
        return url
      }
      else {
        return '//' + url
      }
    },

  },
}

</script>

<style lang="scss">

</style>
