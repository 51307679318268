<template>
  <div class="table-card__item-content"
       :class="{show : show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <DefaultCheckbox
            v-if="navTabs.meest.active"
            class="mt-1"
            :selectedNow="selectedNow"
            :dataValue="item.id"
        />
        <div class="table-card__item-number d-flex">
<!--          <StatusIcoBtn-->
<!--              class="mr-2 d-flex mt-1"-->
<!--              :type="'delivered'"-->
<!--          />-->
          <router-link
              v-if="navTabs.meest.active"
              class="table-card__item-number"
              :to="$store.getters.GET_PATHS.reportsConsolidationMeest + '/show/' + item.id">
            #{{item.id}}
          </router-link>
          <router-link
              v-else
              class="table-card__item-number"
              :to="$store.getters.GET_PATHS.reportsConsolidationMeestSent + '/show/' + item.id">
            #{{item.id}}
          </router-link>
        </div>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('payPalCases_status.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'tracking_number'"
              />
            </div>
          </div>
          <div class="table-card__item-col" v-if="navTabs.meestSent.active">
            <div class="table-card__item-label">
              {{$t('payPalCases_status.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item.sent_at"
                  :date="true"
                  :dateType="'DD MMM, YYYY'"
              />
            </div>
          </div>
        </div>

        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('payPalCases_trackingNumber.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'comment'"
              />
            </div>
          </div>
        </div>

        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i">
            <LinkButton
                :value="$t(`common_edit.localization_value.value`)"
                :type="'edit'"
                :link="$store.getters.GET_PATHS.reportsConsolidationMeest + '/' + item.id"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  // import StatusIcoBtn from "../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";

  export default {
    name: "ConsolidationMeestTableMobile",

    components: {
      ValueHelper,
      // StatusIcoBtn,
      LinkButton,
      DefaultCheckbox,
    },

    props: {
      item: Object,
      statusTranslation: String,
      commentsArray: Array,
      selectedNow: Boolean,
      navTabs: Object,
    },

    data() {
      return {
        show: false
      }
    },

    methods: {

    },

  }
</script>

<style scoped>

</style>
