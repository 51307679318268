<template>
  <PayPalCasesTableUser
      :filterGetParams="filterGetParams"
      :countFilterParams="countFilterParams"

      @filter="filter"
      @changeFilter="changeFilter"
      @resetFilter="resetFilter"
      @showMore="showMore"
      @downloadFiles="downloadFiles"
  />
</template>

<script>
  import PayPalCasesTableUser from "./PayPalCasesTableUser/PayPalCasesTableUser";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {payPalCasesMixin} from "../../../../../mixins/payPalCasesMixins/payPalCasesMixin";

  export default {
    name: "PayPalCasesTable",

    components: {
      PayPalCasesTableUser,
    },

    mixins: [routeFilter, payPalCasesMixin],

    data(){
      return {
        forPage: this.$store.getters.getPayPalCasesForPage,
        page: 1,
        onePage: 1,

        filterDate: [],

        filterOpenedDate: [],
        filterClosedDate: [],

        filterId: '',
        filterUserId: '',
        filterUserName: '',
        filterUserAdminId: '',
        filterUserAdminName: '',

        filterTransactionId: '',

        filterComplaintTypeId: '',
        filterComplaintTypeName: '',

        filterReasonsId: '',
        filterReasonsName: '',

        filterExternalComplainId: '',
        filterComplainId: '',

        filterStatus: '',

        countFilterParams: 0,
        filterGetParams: {},

        createData: null,
      }
    },

    mounted() {
      this.filter()
    },

    methods: {
      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,

          date: this.$route.query.date,

          openedDate: this.$route.query.openedDate,
          closedDate: this.$route.query.closedDate,

          id: this.$route.query.id,
          userId: this.$route.query.userId,
          userName: this.$route.query.userName,
          userAdminId: this.$route.query.userAdminId,
          userAdminName: this.$route.query.userAdminName,

          transactionId: this.$route.query.transactionId,

          complaintTypeId: this.$route.query.complaintTypeId,
          complaintTypeName: this.$route.query.complaintTypeName,

          reasonsId: this.$route.query.reasonsId,
          reasonsName: this.$route.query.reasonsName,

          externalComplainId: this.$route.query.externalComplainId,

          complaintId: this.$route.query.complaintId,

          status: this.$route.query.status,
        }
      },

      changeFilter(data) {
        data.page = this.onePage
        data.count = this.forPage

        this.getFilterParam()
        this.changeRouteParams(this.filterGetParams, data)

        this.filter()
      },

      resetFilter() {
        this.$router.push({path: '', query: {}})

        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let forPage, page, url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)


        // Generate filter URL
        url = this.generateFilterUrl(page, forPage)

        // True if get next page
        if (next) this.$store.commit('setNextPayPalCasesPage', true)

        // save filter params in store
        this.$store.commit('setPayPalCasesFilter', window.location.search)

        this.$store.dispatch('fetchPayPalCases', url).then(() => {
          // skip get next page
          this.$store.commit('setNextPayPalCasesPage', false)
        })

        this.checkCountFilter(['userName', 'complaintTypeName', 'reasonsName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: false})

        console.log(this.filterStatus);
        this.generateFilterQueryParams(
            myQuery,
            {
              filterId: 'id',
              filterUserId: 'byUser',
              filterComplaintTypeId: 'PaymentTransactionComplaintComplaintTypeId',
              filterReasonsId: 'PaymentTransactionComplaintReasonId',
              filterStatus: 'PaymentTransactionComplaintStatus',
              filterExternalComplainId: 'PaymentTransactionComplaintExternalComplainId',
              filterComplaintId: 'PaymentTransactionComplaintId',
              filterTransactionId: 'byPaymentTransactionPaymentId',
            },
        )

        this.filterDate = this.$route.query.date !== undefined ? this.$route.query.date : ''
        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')
          myQuery.whereIn(
              'betweenCreatedAt',
              date
          )
        }

        this.filterOpenedDate = this.$route.query.openedDate !== undefined ? this.$route.query.openedDate : ''
        if (this.filterOpenedDate.length > 0) {
          let openedDate = this.generateDateForFilter(this.filterOpenedDate, 'YYYY-MM-DD')
          myQuery.whereIn(
              'betweenOpenedDate',
              openedDate
          )
        }

        this.filterClosedDate = this.$route.query.closedDate !== undefined ? this.$route.query.closedDate : ''
        if (this.filterClosedDate.length > 0) {
          let closedDate = this.generateDateForFilter(this.filterClosedDate, 'YYYY-MM-DD')
          myQuery.whereIn(
              'betweenClosedDate',
              closedDate
          )
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      showMore() {
        this.generateNextPAgeUrl(this)

        this.filter(true)
      },

      downloadFiles(type) {
        let forPage, page, url, exportIds

        exportIds = this.getCheckedRows('row-name')

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(false, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        url = this.generateFilterUrl(page, forPage, exportIds)

        this.$store.dispatch('getExportPayPalCases', {filter: url, exportType: type}).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            const blob = new Blob([response.data], {type: `application/${type}`})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = `PayPal Cases.${type}`
            link.click()
            URL.revokeObjectURL(link.href)
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })
      }
    }
  }
</script>

<style scoped>

</style>
