<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">

      <div class="table-filter__item">
        <DefaultInput
            :label="'Id'"
            :type="'text'"
            v-model="complaintId"
        />
      </div>

      <div class="table-filter__item">
        <DefaultInput
            :label="$t('payPalCases_transactionId.localization_value.value')"
            :type="'text'"
            v-model="transactionId"
        />
      </div>

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_user'])"></span>

        <SearchSelect
            :options="options"
            :label="$t('common_user.localization_value.value')"
            :functionSearch="functionSearch"
            @change="changeUsersFilter"
            :selected="userName"
            :typeSelect="'users'"
        />
      </div>

      <div class="table-filter__item"
           v-if="_.has(createData, 'types')">
        <DefaultSelect
            class="w-100"
            :options="createData.types"
            :label="$t('payPalCases_caseType.localization_value.value')"
            :otherValue="'currentTranslation'"
            :selected="{
              id: complaintTypeId,
              currentTranslate: {
                name: complaintTypeName
              }
            }"
            @change="setComplaintType"
        />
      </div>

      <div class="table-filter__item"
           v-if="_.has(createData, 'reasons')"
      >
        <DefaultSelect
            class="w-100"
            :options="createData.reasons"
            :label="$t('payPalCases_caseReason.localization_value.value')"
            :otherValue="'currentTranslation'"
            :selected="{
              id: reasonsId,
              // name: reasonsName,
              currentTranslate: {
                name: reasonsName
              }
            }"
            @change="setReason"
        />
      </div>

      <div class="table-filter__item"
           v-if="_.has(createData, 'reasons')"
      >
        <DefaultSelect
            class="w-100"
            :options="PAYPAL_CASES_ARRAY_STATUSES"
            :label="$t('payPalCases_caseStatus.localization_value.value')"
            :otherValue="'translation'"
            :selected="_.find(PAYPAL_CASES_ARRAY_STATUSES, {value: status})"
            @change="setStatus"
        />
      </div>

<!--      <div class="table-filter__item"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <span class="admin-edit" @click="editTranslate(['cuspayments_date'])"></span>-->
<!--        <DatePickerDefault-->
<!--            :label="$t('cuspayments_date.localization_value.value')"-->
<!--            v-model="date"-->
<!--        >-->
<!--          <template slot="body">-->
<!--            <date-picker-->
<!--                v-model="date"-->
<!--                ref="datePicker"-->
<!--                valueType="format"-->
<!--                range-->
<!--                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"-->
<!--                :format="'MM/DD/YY'"-->
<!--                :placeholder="'mm/dd/yy'"-->
<!--            ></date-picker>-->
<!--          </template>-->
<!--        </DatePickerDefault>-->
<!--      </div>-->

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['payPalCases_openingDate'])"></span>
        <DatePickerDefault
            :label="$t('payPalCases_openingDate.localization_value.value')"
            v-model="openedDate"
        >
          <template slot="body">
            <date-picker
                v-model="openedDate"
                ref="datePicker"
                valueType="format"
                range
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['payPalCases_closingDate'])"></span>
        <DatePickerDefault
            :label="$t('payPalCases_closingDate.localization_value.value')"
            v-model="closedDate"
        >
          <template slot="body">
            <date-picker
                v-model="closedDate"
                ref="datePicker"
                valueType="format"
                range
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="table-filter__item">
        <DefaultInput
            :label="$t('payPalCases_paypalNumberOfComplaint.localization_value.value')"
            :type="'text'"
            v-model="externalComplainId"
        />
      </div>

    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DatePicker from 'vue2-datepicker'
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {PAYPAL_CASES_ARRAY_STATUSES} from "../../../../../../../staticData/staticVariables";

  export default {
    name: "PayPalCasesFilter",
    components: {
      DefaultSelect,
      DatePickerDefault,
      SearchSelect,
      FilterBlock,
      MainButton,
      DatePicker,
      DefaultInput,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      filterGetParams: Object,
    },

    mixins: [filterFunc, usersFunc],

    data(){
      return {
        date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY') : '',

        openedDate: this.filterGetParams.openedDate ? this.generateFilterDate(this.filterGetParams.openedDate, 'MM/DD/YY') : '',
        closedDate: this.filterGetParams.closedDate ? this.generateFilterDate(this.filterGetParams.closedDate, 'MM/DD/YY') : '',

        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',

        complaintTypeId: this.filterGetParams.complaintTypeId ? this.filterGetParams.complaintTypeId : '',
        complaintTypeName: this.filterGetParams.complaintTypeName ? this.filterGetParams.complaintTypeName : '',

        transactionId: this.filterGetParams.transactionId ? this.filterGetParams.transactionId : '',

        reasonsId: this.filterGetParams.reasonsId ? this.filterGetParams.reasonsId : '',
        reasonsName: this.filterGetParams.reasonsName ? this.filterGetParams.reasonsName : '',

        status: this.filterGetParams.status ? this.filterGetParams.status : '',

        complaintId: this.filterGetParams.complaintId ? this.filterGetParams.complaintId : '',
        externalComplainId: this.filterGetParams.externalComplainId ? this.filterGetParams.externalComplainId : '',

        options: [],
        createData: {},
        PAYPAL_CASES_ARRAY_STATUSES: PAYPAL_CASES_ARRAY_STATUSES,

        filterCounts: [
          'date',
          'openedDate',
          'closedDate',
          'userName',
          'userId',
          'complaintTypeId',
          'complaintTypeName',
          'reasonsId',
          'reasonsName',
          'status',
          'externalComplainId',
          'complaintId',
          'transactionId',
        ],
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''

        this.openedDate = newVal.openedDate ? this.generateFilterDate(newVal.openedDate, 'MM/DD/YY') : ''
        this.closedDate = newVal.closedDate ? this.generateFilterDate(newVal.closedDate, 'MM/DD/YY') : ''

        this.userId = newVal.userId ? newVal.userId : ''
        this.userName = newVal.userName ? newVal.userName : ''

        this.complaintTypeId = newVal.complaintTypeId ? newVal.complaintTypeId : ''
        this.complaintTypeName = newVal.complaintTypeName ? newVal.complaintTypeName : ''

        this.reasonsId = newVal.reasonsId ? newVal.reasonsId : ''
        this.reasonsName = newVal.reasonsName ? newVal.reasonsName : ''

        this.transactionId = newVal.transactionId ? newVal.transactionId : ''

        this.externalComplainId = newVal.externalComplainId ? newVal.externalComplainId : ''

        this.complaintId = newVal.complaintId ? newVal.complaintId : ''

        this.status = newVal.status ? newVal.status : ''
      },

    },

    mounted() {
      this.$store.dispatch('getPayPalCasesCreate').then(response => {
        this.createData = this.getRespData(response)
      })
    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      functionSearch(value, loading) {
        loading(true)
        this.getFilteredUsers(value, this, 'options', loading, true)
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        data.date = this.changeDateParams(this, 'date')
        data.openedDate = this.changeDateParams(this, 'openedDate')
        data.closedDate = this.changeDateParams(this, 'closedDate')

        this.$emit('changeFilter', data)
      },

      changeUsersFilter(value) {
        this.userId = value.id
        this.userName = value.contacts[0].user_full_name
      },

      changeShopTypesFilter(val) {
        this.shop = val.id
      },

      setComplaintType(val) {
        this.complaintTypeId = val.id
        this.complaintTypeName = val.currentTranslate.name
      },

      setReason(val) {
        this.reasonsId = val.id
        this.reasonsName = val.currentTranslate.name
      },

      setStatus(val) {
        this.status = val.value
      },

    },
  }
</script>

<style scoped>

</style>
