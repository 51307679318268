<template>
  <div class="table-card__item-content"
       :class="{show : show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <DefaultCheckbox
            class="mt-1"
            :selectedNow="selectedNow"
            :dataValue="item.id"
        />
        <div class="table-card__item-number d-flex">
          <StatusIcoBtn
              class="mr-2 d-flex mt-1"
              :type="'delivered'"
          />
          #{{item.id}}
        </div>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">{{ item.opened_date | moment("DD MMM, YYYY") }}</div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('payPalCases_caseType.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item.complaint_type"
                  :deep="'currentTranslate.name'"
              />
            </div>
          </div>
        </div>

        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('payPalCases_status.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ statusTranslation }}
            </div>
          </div>
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('payPalCases_transaction.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <template v-if="item.payment_transaction">
                <span class="table-link btn-style"
                      @click="$emit('showTransactionInfo')"
                >#{{ item.payment_transaction_id }}: ${{item.payment_transaction.freeAmount}}</span>
              </template>
              <template v-else>
                —
              </template>
            </div>
          </div>
        </div>

        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('payPalCases_trackingNumber.localization_value.value')}}
            </div>
            <div class="table-card__item-info">

              <TableTrackingLog
                  v-if="item.deliveryState"
                  class="mt-1"
                  :trackingLogItem="null"
                  :trackingStateEvent="item.deliveryState"
              />

              <v-popover
                  class="site-tooltip"
                  offset="5"
                  placement="top"
                  trigger="hover"
              >
                <div class="table-row ellipsis">
                  <ValueHelper
                      :value="item"
                      :deep="'payment_transaction.tracking_number'"
                  />
                </div>
                <template slot="popover">
                  <p>
                    <ValueHelper
                        :value="item"
                        :deep="'payment_transaction.tracking_number'"
                    />
                  </p>
                </template>
              </v-popover>
            </div>
          </div>

          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('payPalCases_sum.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.amount }}
            </div>
          </div>
        </div>

        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('payPalCases_paypalNumberOfComplaint.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'external_complain_id'"
              />
            </div>
          </div>
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('payPalCases_solvedDate.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.close_date | moment("DD MMM, YYYY") }}
            </div>
          </div>
        </div>

        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('payPalCases_comments.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <v-popover
                  class="site-tooltip"
                  offset="5"
                  placement="top"
                  trigger="hover"
              >
                          <span v-if="checkDialogArray(item)">
                            {{ item.dialogs[0].message }}
                          </span>
                <template slot="popover">
                  <template v-if="checkDialogArray(item)">
                    <template v-for="(commentItem, commentIndex) in item.dialogs">
                      <div
                          class="comment-item"
                          v-bind:class="{'comment-item--right-align': commentItem.author && !commentItem.author.isAdmin}"
                          :key="commentIndex"
                      >
                        <p><b>{{ commentItem.created_at | moment("DD MMM, YYYY") }}</b></p>
                        <p>{{commentItem.message}}</p>
                      </div>
                    </template>
                  </template>

                </template>
              </v-popover>
            </div>
          </div>
        </div>

        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i">
            <LinkButton
                v-if="!isAdmin"
                :value="$t(`common_react.localization_value.value`)"
                :type="'edit'"
                @click.native="() => {$emit('openReactOnComplainPopup', item.id)}"
            />
          </div>
          <div class="table-card__item-btn-i" v-if="!isAdmin">
            <LinkButton
                :value="$t(`common_detail.localization_value.value`)"
                :link="$store.getters.GET_PATHS.financePayPalCases + '/' + item.id"
            />
          </div>
          <div class="table-card__item-btn-i">
            <LinkButton
                v-if="isAdmin"
                :value="$t(`common_edit.localization_value.value`)"
                :type="'edit'"
                :link="$store.getters.GET_PATHS.financePayPalCases + '/' + item.id"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import StatusIcoBtn from "../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import TableTrackingLog from "@/components/coreComponents/TableComponents/TableTrackingLog/TableTrackingLog";

  export default {
    name: "PayPalCasesTableMobile",

    components: {
      TableTrackingLog,
      ValueHelper,
      StatusIcoBtn,
      LinkButton,
      DefaultCheckbox,
    },

    props: {
      item: Object,
      statusTranslation: String,
      commentsArray: Array,
      selectedNow: Boolean,
    },

    data() {
      return {
        show: false
      }
    },

    methods: {
      checkDialogArray(item) {
        if (item?.dialogs) {
          return item.dialogs.length > 0
        }
      },
    },

  }
</script>

<style scoped>
.table-card__item-content .ellipsis {
  max-width: 220px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}
</style>
