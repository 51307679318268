<template>
  <modal
      @close="$emit('close')"
      class="custom-popup react-on-complain-popup"
  >
    <template slot="header">
      {{ $t('payPalCases_reactOnComplain.localization_value.value') }}
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="section-label large">
          {{ $t('payPalCases_reactOnComplainText.localization_value.value') }}
        </div>
        <div class="custom-row">
          <div class=" custom-col">
            <TextareaDefault
                :label="$t('payPalCases_messageToAdministrator.localization_value.value')"
                v-bind:class="{'ui-no-valid': PCPopup.validation.message}"
                :error="PCPopup.validation.message"
                :errorTxt="$t(`${PCPopup.validationTranslate.message}.localization_value.value`)"
                v-model="PCPopup.data.message"
            />
          </div>
          <div class=" custom-col">
            <DropZone class="drop-zone-bg"
                      :parentFiles="PCPopup.data.Files.data.files"
                      :multiple="true"
                      :maxCount="PCPopup.data.Files.data.maxCountFiles - PCPopup.data.Files.data.downloadFiles.length"
                      :maxSize="PCPopup.data.Files.data.maxSizeFiles"
                      :accept="'image/x-png,image/gif,image/jpeg,application/pdf'"
                      @changeImg="changeUserMessageImg"
            />
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="import-transaction__btn d-flex align-items-center flex-wrap">
        <span
            class="site-link site-link--alt import-transaction__btn-i mt-3 mr-3 mb-3"
            @click="$emit('close')"
        >
          {{ $t('common_cancel.localization_value.value') }}
        </span>

        <MainButton
            :value="$t('common_send.localization_value.value')"
            class="import-transaction__btn-i"
            @click.native="sendUserMessage"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import {PayPalCases} from "../../models/PayPalCases";
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
  import DropZone from "../../../../coreComponents/DropZone/DropZone";
  import TextareaDefault from "../../../../UI/textarea/TextareaDefault/TextareaDefault";
  import {payPalCasesMixin} from "../../../../../mixins/payPalCasesMixins/payPalCasesMixin";

  export default {
    name: "ReactOnComplainPopup",
    components: {
      Modal,
      MainButton,
      DropZone,
      TextareaDefault,
    },

    mixins: [payPalCasesMixin],

    props: {
      complaintId: Number,
    },

    data(){
      return {
        PCPopup: new PayPalCases({files: 5}),
      }
    },

    mounted() {
      this.PCPopup.setId(this.complaintId)
    },

    methods: {
    }
  }

</script>

<style lang="scss">
  @import "../../../../../scss/colors";
  .react-on-complain-popup{

    .modal-component__inner{
      max-width: 685px;
    }

    .large{
      color: $borderBrown;
    }
  }




</style>
