<template>
  <ConsolidationMeestTableUser
      :filterGetParams="filterGetParams"
      :countFilterParams="countFilterParams"
      :navTabs="navTabs"
      @changeTab="changeTab"
      @filter="filter"
      @changeFilter="changeFilter"
      @resetFilter="resetFilter"
      @showMore="showMore"
      @sentConsolidationMeest="sentConsolidationMeest"
  />
</template>

<script>
  import ConsolidationMeestTableUser from "./ConsolidationMeestTableUser/ConsolidationMeestTableUser";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {payPalCasesMixin} from "../../../../../mixins/payPalCasesMixins/payPalCasesMixin";

  export default {
    name: "ConsolidationMeestTable",

    components: {
      ConsolidationMeestTableUser,
    },

    mixins: [routeFilter, payPalCasesMixin],

    // watch: {
    //   routerReturnType(type) {
    //     if(type){
    //       this.reload()
    //     }
    //   },
    // },

    computed: {
      // routerReturnType() {
      //   return this.$route.meta.breadcrumbs.items.consolidationMeest.link === this.$store.getters.GET_PATHS.reportsConsolidationMeest ?
      //       'meest' :
      //       'meest-sent'
      // },
    },

    data(){
      return {
        forPage: this.$store.getters.getConsolidationMeestForPage,
        page: 1,
        onePage: 1,

        filterId: '',
        filterTrackingNumber: '',
        filterType: '',

        countFilterParams: 0,
        filterGetParams: {},

        navTabs: {
          meest: {
            active: true,
            label: 'consolidationMeest_meest',
            name: 'meest',
            id: false,
          },
          meestSent: {
            active: false,
            label: 'consolidationMeest_meestSent',
            name: 'meestSent',
            id: false,
          },
        }
      }
    },

    mounted() {
      // console.log();
      this.filter()
    },

    methods: {

      sentConsolidationMeest() {
        let exportIds = this.getCheckedRows('row-name')
        if(exportIds.length === 0) return this.openNotify('error', 'consolidationMeest_selectOrder')

        // let urlIds = this.$store.getters.GET_PATHS.reportsConsolidationMeestCreation + '?id='
        // exportIds.map((id, index) => {
        //   if(index > 0) urlIds += ','
        //   urlIds += id
        // })

        let text = {
          title: 'consolidationMeest_sentMeest',
          txt: '',
          yes: 'common_yes',
          no: 'common_no'
        }


        let confirm = () => {

          this.$store.dispatch('sendConsolidationMeest', {ids: exportIds}).then((response) => {

            if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                this.reload()
                this.openNotify('success', 'common_notificationRecordChanged')
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }

          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)

      },

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          type: this.$route.query.type,

          id: this.$route.query.id,
          trackingNumber: this.$route.query.trackingNumber,
        }
      },

      changeFilter(data) {
        data.page = this.onePage
        data.count = this.forPage

        this.getFilterParam()
        this.changeRouteParams(this.filterGetParams, data)

        this.filter()
      },

      resetFilter() {
        this.$router.push({path: '', query: {}})

        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let forPage, page, url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)


        // Generate filter URL
        url = this.generateFilterUrl(page, forPage)

        // True if get next page
        if (next) this.$store.commit('setNextConsolidationMeestPage', true)

        // save filter params in store
        this.$store.commit('setConsolidationMeestFilter', window.location.search)

        this.$store.dispatch('fetchConsolidationMeest', url).then(() => {
          // skip get next page
          this.$store.commit('setNextConsolidationMeestPage', false)
        })

        this.checkCountFilter(['type'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: false})

        this.generateFilterQueryParams(
            myQuery,
            {
              filterId: 'ReturnDocumentUnionId',
              filterTrackingNumber: 'ReturnDocumentUnionTrackingNumber',
            },
        )

        if(this.navTabs.meest.active)
          myQuery.where('bySentAt', '0')
        else
          myQuery.where('bySentAt', '1')

        return this.generateGetParamsFromQuery(myQuery, forPage, page)
      },

      showMore() {
        this.generateNextPAgeUrl(this)

        this.filter(true)
      },
    }
  }
</script>

<style scoped>

</style>
