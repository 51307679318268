<template>
  <div class="table-filter-wrap">
    <template
        v-if="$store.getters.getPayPalCases.length > 0 && $store.getters.getPayPalCasesLoading !== true">
      <div class="site-table-wrap products-table scrolling-macbook-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'payPalCases_id',
            'common_user',
            'payPalCases_date',
            'payPalCases_caseType',
            'payPalCases_status',
            'payPalCases_caseReason',
            'payPalCases_transaction',
            'payPalCases_trackingNumber',
            'payPalCases_paypalNumberOfComplaint',
            'payPalCases_sum',
            'payPalCases_solvedDate',
            'payPalCases_comments',
            'payPalCases_manage',
            ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th></th>
            <th>{{$t('payPalCases_id.localization_value.value')}}</th>
            <th v-if="isAdmin">{{$t('common_user.localization_value.value')}}</th>
            <th>{{$t('payPalCases_date.localization_value.value')}}</th>
            <th>{{$t('payPalCases_caseType.localization_value.value')}}</th>
            <th>{{$t('payPalCases_status.localization_value.value')}}</th>
            <th>{{$t('payPalCases_caseReason.localization_value.value')}}</th>
            <th>{{$t('payPalCases_transaction.localization_value.value')}}</th>
            <th>{{$t('payPalCases_trackingNumber.localization_value.value')}}</th>
            <th>{{$t('payPalCases_paypalNumberOfComplaint.localization_value.value')}}</th>
            <th>{{$t('payPalCases_sum.localization_value.value')}}</th>
            <th>{{$t('payPalCases_solvedDate.localization_value.value')}}</th>
            <th>{{$t('payPalCases_comments.localization_value.value')}}</th>
            <th>{{$t('payPalCases_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>
  
          <tr v-for="(item, index) in $store.getters.getPayPalCases" :key="index">
            <td>
              <DefaultCheckbox
                  class="empty-label mt-1"
                  :selectedNow="selectedNow"
                  :dataValue="item.id"
              />
            </td>
            <td>
              <div class="d-flex align-items-center">
                <StatusIcoBtn
                    class="mr-2"
                    :type="'delivered'"
                />
                #{{item.id}}
              </div>
            </td>
            <td v-if="isAdmin">
              <TableUserColumn
                  v-if="item.payment_transaction"
                  :item="item.payment_transaction"
              />
              <template v-else>
                —
              </template>
            </td>
            <td>
              {{ item.opened_date | moment("DD MMM, YYYY") }}
            </td>
            <td>
              <ValueHelper
                  :value="item.complaint_type"
                  :deep="'currentTranslate.name'"
              />
            </td>
            <td>
              <ValueHelper
                  :value="getCaseStatusTranslation(item.status)"
                  :translation="true"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'complaint_reason.currentTranslate.name'"
              />
            </td>
            <td>
              <template v-if="item.payment_transaction">
                <span class="table-link btn-style"
                      @click="showTransactionInfo(item.payment_transaction_id, item.payment_transaction.payment_system)"
                >#{{ item.payment_transaction_id }}: ${{item.payment_transaction.freeAmount}}</span>
              </template>
              <template v-else>
                —
              </template>

              </td>
            <td>
              <div class="table-row">
                <TableTrackingLog
                    v-if="item.deliveryState"
                    class="mt-1"
                    :trackingLogItem="null"
                    :trackingStateEvent="item.deliveryState"
                />

                <TooltipTextHelper>
                  <template slot="text">
                    <a v-if="item.tracking_number_url" :href="item.tracking_number_url" class="site-link btn-style" target="_blank">
                      <ValueHelper
                          :value="item"
                          :deep="'payment_transaction.tracking_number'"
                      />
                    </a>
                    <ValueHelper
                        v-else
                        :value="item"
                        :deep="'payment_transaction.tracking_number'"
                    />
                  </template>
                  <template slot="paragraph">
                    <ValueHelper
                        :value="item"
                        :deep="'payment_transaction.tracking_number'"
                    />
                  </template>
                </TooltipTextHelper>
              </div>
            </td>
            <td>
              <TooltipTextHelper>
                <template slot="text">
                  <ValueHelper
                      :value="item"
                      :deep="'external_complain_id'"
                  />
                </template>
                <template slot="paragraph">
                  <ValueHelper
                      :value="item"
                      :deep="'external_complain_id'"
                  />
                </template>
              </TooltipTextHelper>
            </td>
            <td class="text-right">
              {{ item.amount }}
            </td>
            <td>
              {{ item.closed_date | moment("DD MMM, YYYY") }}
            </td>
            <td width="12%">
              <v-popover
                  class="site-tooltip"
                  offset="5"
                  placement="top"
                  trigger="hover"
                  v-if="checkDialogArray(item)"
              >
                <div class="ellipsis">
                  {{ item.dialogs[0].message }}
                </div>
                <template slot="popover">
                  <div class="comment-list">
                    <template v-for="(commentItem, commentIndex) in item.dialogs">
                      <div
                          class="comment-item"
                          v-bind:class="{'comment-item--right-align': commentItem.author && !commentItem.author.isAdmin}"
                          :key="commentIndex"
                      >
                        <p><b style="font-size: 13px">{{ commentItem.created_at | moment("DD MMM, YYYY") }}</b></p>
                        <p style="font-size: 13px">{{commentItem.message}}</p>
                      </div>
                    </template>

                  </div>
  
                </template>
              </v-popover>

            </td>
            <td width="10%">
              <div class="table-right table-manage-list table-manage-list--small"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                'common_react',
                'common_edit',
                ])"></div>
                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                  <template slot="item">
                    <LinkButton
                        v-if="!isAdmin"
                        :value="$t(`common_react.localization_value.value`)"
                        :type="'edit'"
                        @click.native="openReactOnComplainPopup(item.id)"
                    />
                    <LinkButton
                        v-if="!isAdmin"
                        :value="$t(`common_detail.localization_value.value`)"
                        :link="$store.getters.GET_PATHS.financePayPalCases + '/' + item.id"
                    />
                    <LinkButton
                        v-if="isAdmin"
                        :value="$t(`common_edit.localization_value.value`)"
                        :type="'edit'"
                        :link="$store.getters.GET_PATHS.financePayPalCases + '/' + item.id"
                    />
                  </template>
                </ManagerButton>
              </div>
            </td>
          </tr>
  
  
  
          </tbody>
        </table>
  
        <div class="table-card" v-else>
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getPayPalCases"
                 :key="index"
            >
              <PayPalCasesTableMobile
                  :item="item"
                  :index="index"
                  :selectedNow="selectedNow"
                  :statusTranslation="$t(`${getCaseStatusTranslation(item.status)}.localization_value.value`)"
                  :commentsArray="getCommentsArray(item.admin_comment, item.user_comment)"
                  @openReactOnComplainPopup="openReactOnComplainPopup"
                  @showTransactionInfo="showTransactionInfo(item.payment_transaction_id, item.payment_transaction.payment_system)"
              />
            </div>
          </div>
        </div>
  
        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                v-if="$store.getters.getPayPalCasesCommonList.next_page_url !== null && $store.getters.getPayPalCases.length > 0"
                @click.native="$emit('showMore')"
                v-bind:class="{'disabled-btn' : $store.getters.getNextPayPalCasesPage}"
                :count="$store.getters.getPayPalCasesCommonList.total - $store.getters.getPayPalCasesForPage * $store.getters.getPayPalCasesCommonList.current_page < $store.getters.getPayPalCasesForPage ?
                    $store.getters.getPayPalCasesCommonList.total - $store.getters.getPayPalCasesForPage * $store.getters.getPayPalCasesCommonList.current_page:
                    $store.getters.getPayPalCasesForPage"
            />
            <ExportBtn class="table-bottom-btn__right"
                       @downloadFiles="type => $emit('downloadFiles', type)"
            />
          </div>
        </div>
      </div>
    </template>

    <template
        v-if="$store.getters.getPayPalCasesLoading === false && $store.getters.getPayPalCases.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>

    <ReactOnComplainPopup
      v-if="isModalRequestRefundsPopup"
      :complaintId="openedComplaintId"
      @close="closeReactOnComplainPopup()"
    />

    <TransactionInfoPopup
        @close="closeTransactionInfoPopup"
        v-if="isModalTransactionInfo"
        :transactionUrl="true"
        :itemId="showItemId"
        :transactionType="transactionType"
    />
  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import StatusIcoBtn from "../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import ReactOnComplainPopup from "../../../../popups/ReactOnComplainPopup/ReactOnComplainPopup";
  import {PAYPAL_CASES_ARRAY_STATUSES} from "../../../../../../../staticData/staticVariables";
  import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import PayPalCasesTableMobile from "./PayPalCasesTableMobile/PayPalCasesTableMobile";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import TransactionInfoPopup from "../../../../../../coreComponents/Popups/TransactionInfoPopup/TransactionInfoPopup";
  import TableTrackingLog from "@/components/coreComponents/TableComponents/TableTrackingLog/TableTrackingLog";
  import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";

  export default {
    name: "PayPalCasesTable",

    components: {
      TooltipTextHelper,
      TableTrackingLog,
      TransactionInfoPopup,
      NoResult,
      ValueHelper,
      PayPalCasesTableMobile,
      TableUserColumn,
      ShowMore,
      ExportBtn,
      DefaultCheckbox,
      LinkButton,
      ManagerButton,
      StatusIcoBtn,
      ReactOnComplainPopup,
    },

    mixins: [mixinDetictingMobile],

    props: {
      countFilterParams: Number,
      selectedNow: Boolean,
    },

    data() {
      return {
        PAYPAL_CASES_ARRAY_STATUSES: PAYPAL_CASES_ARRAY_STATUSES,
        isModalPayPalCasesToWarehouse: false,
        isModalRequestRefundsPopup: false,
        openedComplaintId: null,

        isModalTransactionInfo: false,
        showItemId: false,
        transactionType: '',
      }
    },

    methods: {
      closePopup(){
        this.isModalPayPalCasesToWarehouse = false
      },

      openReactOnComplainPopup(id){
        this.isModalRequestRefundsPopup = true
        this.openedComplaintId = id
      },

      closeReactOnComplainPopup() {
        this.isModalRequestRefundsPopup = false
      },

      getCaseStatusTranslation(statusName) {
        let currentStatusObj = this.PAYPAL_CASES_ARRAY_STATUSES.filter(value => value.name === statusName)
        return currentStatusObj ? currentStatusObj[0]?.translation : ''
      },

      getCommentsArray() {
        //преобразовываем псевдомассив arguments в массив с помощью spread operator и возвращаем массив, состоящий из аргументов функции, которые не равны null
        return [...arguments].filter(item => item !== null)
      },

      checkDialogArray(item) {
        if (item?.dialogs) {
          return item.dialogs.length > 0
        }
      },

      showTransactionInfo(id, type) {
        this.$store.dispatch('getCusPayments', id).then(() => {
          this.isModalTransactionInfo = true
          this.transactionType = type
        })
      },

      showTransactionInfoPopup() {
        this.isModalTransactionInfo = true
      },

      closeTransactionInfoPopup() {
        this.isModalTransactionInfo = false
      },
    }

  }
</script>

<style lang="scss" scoped>

  .products-table .table-manage-list .manager-btn{
    width: 165px;
  }

  .products-table .site-table tbody td:last-child {
    padding-right: 15px;
  }

  .products-table .ellipsis{
    max-width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inherit;
  }

  .clipping-text{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  td:first-child{
    max-width: 33px;
  }

  td:last-child{
    max-width: 120px;
  }

  .comment-item{
    margin: 0 -15px;
    padding: 10px 15px;
    display: block;

    &:nth-child(even){
      background: #F8F4EE;
    }

    &--right-align {
      text-align: right;
    }

    p{
      margin-bottom: 0;
      &:first-child{
        margin-bottom: 5px;
      }
    }
  }

</style>
