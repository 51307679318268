
<template>
  <div class="fragment">

    <div class="horiz-block"
         v-if="navTabs.meest.active"
    >
      <div class="horiz-block__inner">

        <div class="filter-parcel-track">
          <div class="filter-parcel-track__item wfc"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['consolidationMeest_sentMeest'])"></span>
            <div class="accept-mn-link btn-style"
                 @click="$emit('sentConsolidationMeest')"
            >
              <div class="accept-mn-link__ico">
                <AcceptMNIco/>
              </div>
              {{$t('consolidationMeest_sentMeest.localization_value.value')}}
            </div>

          </div>
        </div>

      </div>
    </div>

<!--         v-bind:class="{'mt-0': navTabs.meest.active}"-->
    <div class="content-top-line content-top-line--with-bottom-line"
    >
      <div class="content-tabs content-tabs--separator"
           v-bind:class="{'loading-tabs': $store.getters.getConsolidationMeestLoading === true,}">
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-bind:class="{active: item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <span class="admin-edit" @click="editTranslate([item.label])"></span>
              {{ $t(item.label + '.localization_value.value')}}
            </div>
          </div>
        </template>
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block" v-if="navTabs.meest.active">
        <DefaultCheckbox
            :label="$t('common_selectAll.localization_value.value')"
            v-model="selectAll"
            @input="(val) => $emit('selectAll', val)"
        />
      </div>
      <div v-if="navTabs.meest.active" class="btn-right-block">
        <router-link :to="$store.getters.GET_PATHS.reportsConsolidationMeestCreation">
          <MainButton class="btn-fit-content ml-2"
                      :value="$t('common_AddNew.localization_value.value')"
                      :ico="true"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus"/>
            </template>
          </MainButton>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
  import AcceptMNIco from "../../../../../../../../public/img/UI-group/accept-mn-icon.svg?inline";


  export default {
    name: "ConsolidationMeestHead",
    components: {
      MainButton,
      PlusIconSVG,
      DefaultCheckbox,
      ToggleFilterButton,
      AcceptMNIco,
    },

    mixins: [
      mixinDetictingMobile
    ],

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    watch: {
      getMeests() {
        this.selectAll = false
      }
    },

    computed: {
      getMeests() {
        return this.$store.getters.getConsolidationMeest
      }
    },

    created() {

    },

    data() {
      return {
        selectAll: false,
      }
    },

    methods: {
      changeTab(val) {
        this.$emit('changeTab', val)
      },
    }
  }
</script>


<style scoped lang="scss">

@import "../../../../../../../scss/mixins/mixins";
@import "../../../../../../../scss/colors";

.horiz-block{
  padding-top: 24px;

  &__inner{
    display: flex;
    align-items: center;
    position: relative;

    @include for-1120{
      align-items: flex-start;
    }

    @include for-680{
      flex-direction: column;
    }
  }

  &__btn{
    @include from-680 {
      margin-left: auto;
    }

    button{
      font-size: 16px;
      line-height: 19px;
      min-width: 110px;
      height: 40px;

      @include for-680 {
        margin-left: 0!important;
      }
    }
  }
}

.accept-mn-link{
  padding: 8px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: $accent;
  transition: .15s;

  &:hover{
    opacity: .7;
  }

  &__ico{
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

</style>

